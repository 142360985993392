import { getAccessToken } from "@privy-io/react-auth";

export async function connectSiborgApi() {
  const accessToken = await getAccessToken();
  const response = await fetch("https://api.siborg.io/auth/privy", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      jwt: accessToken
    })
  });

  if (!response.ok) {
    throw new Error("Failed to connect to Siborg API");
  }

  return await response.json();
}

export async function fetchQuests(siborgAccessToken: any) {
  const response = await fetch(
    "https://api.siborg.io/quests/code?toDate=2023-12-01T00%3A00%3A00.000Z&fromDate=2026-12-01T00%3A00%3A00.000Z",
    {
      headers: {
        Authorization: `Bearer ${siborgAccessToken}`
      }
    }
  );
  return await response.json();
}

export async function postNewQuest(formValues: any, siborgAccessToken: any) {
  fetch("https://api.siborg.io/quests/code", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${siborgAccessToken}`
    },
    body: JSON.stringify({
      name: formValues.name,
      twitter_space_id: formValues.twitter_space_id,
      twitter_username: formValues.twitter_username,
      from_date: formValues.from_date,
      to_date: formValues.to_date,
      code: formValues.code,
      max_users: formValues.max_users,
      score_reward: formValues.score_reward
    })
  });
}

export async function modifyQuest(formValues: any, siborgAccessToken: any) {
  fetch(`https://api.siborg.io/quests/code/${formValues.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${siborgAccessToken}`
    },
    body: JSON.stringify({
      name: formValues.name,
      from_date: formValues.from_date,
      to_date: formValues.to_date,
      code: formValues.code,
      max_users: formValues.max_users,
      score_reward: formValues.score_reward
    })
  });
}

export async function fetchSpaces(query: string) {
  const spaces = await fetch(`https://api.siborg.io/search/spaces?query=${query}`);
  return await spaces.json();
}

export async function fetchAuthUsers() {
  const authUsers = await fetch("https://api.siborg.io/config");
  return await authUsers.json();
}
