import { useRouter } from "next/router";
import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { connectSiborgApi } from "./siborgApi/siborg";

export interface AuthContextType {
  siborgAccessToken: string | null;
  // eslint-disable-next-line no-unused-vars
  setSiborgAccessToken: (token: string | null) => void;
  authorizedAdminUser: boolean;
  twitterUsername: string;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [authorizedAdminUser, setAuthorizedAdminUser] = useState<boolean>(true);
  const [twitterUsername, setTwitterUsername] = useState<string>("");
  const router = useRouter();

  useEffect(() => {
    const connectToSiborgApi = async () => {
      try {
        const data = await connectSiborgApi();
        if (data.jwt) {
          setAccessToken(data.jwt);
          setTwitterUsername(data.user.twitter_username);
          // Fetch authorized users
          const response = await fetch("https://api.siborg.io/config");
          const config = await response.json();

          // Check if the user is an admin authorized
          if (!config.WL_USERS_IDS.includes(data.user.id)) {
            setAuthorizedAdminUser(false);
            console.log("User is not authorized to access this page");
          }
        }
      } catch (error) {
        console.error("Failed to connect to Siborg API", error);
      }
    };

    if (!accessToken) {
      connectToSiborgApi();
    }
  }, [accessToken, setAccessToken, router]);

  return (
    <AuthContext.Provider
      value={{
        siborgAccessToken: accessToken,
        setSiborgAccessToken: setAccessToken,
        authorizedAdminUser: authorizedAdminUser,
        twitterUsername: twitterUsername
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
